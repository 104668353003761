$ns: u-margin;

.#{$ns} {

    $margin-type: top right bottom left;

    $margins: (
        'none':     0px,
        'xxs':      6px,
        'xs':       12px,
        'sm':       21px,
        'md':       33px,
        'ml':       40px,
        'lg':       60px,
        'xl':       72px,
        'xxl':      99px
    );

    $breakpoints: (lg, ml, md, sm, xs);
    $breakpoint-count: length($breakpoints) + 1;
    $loop-count: 1;


        @each $type in $margin-type {

            &-#{$type} {

                @each $margin-size, $margin in $margins {

                    &-#{$margin-size} {

                        margin-#{$type}: $margin !important;

                    }
                }
            }
        }

        $breakpoint-count: $breakpoint-count - 1;
        $loop-count: $loop-count + 1;

    &-left {
        margin-left: 6px;
    }

    &-auto {
        margin-right: auto;
        margin-left: auto;
    }


    &-clear {

        &-top {
            margin-top: 0 !important;
        }

        &-bottom {
            margin-bottom: 0;
        }

        &-horz {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

    }

}
