.product-tile {
	@extend %clearfix;
	position: relative;
	text-align: center;
	img {
		max-width: 100%;
	}
	.product-image {
		height: auto;
		position: relative;
		.quickview {
			background-color: rgba($citrus, 0.8);
			color: #fff;
			display: none;
			width: 55%;//For IE
			width: max-content;
			max-width: 75%;
			top: 50%;
			left: 50%;
			padding: 0.5em;
			position: absolute;
			transform: translate(-50%, -50%);
			i {
				margin-left: 0.5em;
			}
		}
		&:hover {
			.quickview {
				display: block;
			}
		}
	}
	.product-name {
		font-family: $sans-serif;
		font-size: 2em;
		margin-top: 1em;
		overflow: auto;
		text-transform: uppercase;

		a {
			color: $black-russian;
		}
	}
	.product-pricing {
		font-family: $sans-serif;
		font-size: 1.5em;
		margin: 0.5em 0;
		.product-standard-price {
			text-decoration: line-through;
		}
	}
	.grid-tile & .product-swatches {
		float: right;
	}
	.product-swatches-all {
		cursor: pointer;
		font-size: 0.9em;
	}
	.product-swatches {
		ul {
			list-style: none outside none;
			margin: 0;
			overflow: hidden;
			padding: 0;
			&.swatch-toggle {
				background: none repeat scroll 0 0 $white;
				display: none;
				position: absolute;
			}
		}
		display: none;

		.pt_product-search-result &, .pt_product-compare & {
   			display: block;
   		}

		&:hover ul.swatch-toggle {
			display: block;
		}
		li {
			float: left;
		}
		.swatch {
			display: block;
			width: 16px;
			img {
				border: 1px solid $white;
				height: 12px;
				padding: 1px;
				width: 12px;
			}
		}
		.selected img, img:hover {
			border-color: $citrus;
		}
	}
	.product-promo {
		font-size: 0.8em;
		margin: 0.5em 0;
		text-transform: uppercase;
	}
	.product-compare {
		float: left;
		line-height: 11px;
		label {
			font-weight: 400;
			line-height: 1;
			padding: 0 0 0 .5em;
			width: auto;
		}
		input {
			float: left;
			margin: 0;
		}
	}
	.product-review {
		margin-bottom: 1em;
		.rating-title {
			display: inline-block;
			margin-right: 1em;
		}
		.rating {
			display: inline-block;
		}
	}
	.wide-tiles & {
		height: auto !important; // overwrite synced heights set on element
		.product-image {
			float: left;
			padding-right: 2em;
		}
		.product-name,
		.product-pricing,
		.product-promo,
		.product-swatches,
		.product-review {
			float: left;
			text-align: left;
			width: 65%;
		}
		.product-name {
			font-size: 1.5em;
			height: auto;
			margin-top: 0;
		}
		.product-pricing {
			font-size: 1.3em;
		}
		.product-swatches {
			margin-bottom: 1em;
		}
	}
}

.capture-product-id {
	left: -999em;
	position: absolute;
}
