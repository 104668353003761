.ui-widget-overlay {
	background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $night-rider;
	opacity: 0.4;
}

.ui-dialog {
	background-color: $white;
	padding: 0;
	.ui-widget-header {
		background-color: $white-smoke;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 23px;
		text-transform: uppercase;
	}
	.ui-dialog-title {
		color: $night-rider;
		font-weight: bold;
	}
	.ui-icon-closethick {
		background-image: url("../images/interface/icon-ui-close.png");
		background-position: left top;
		height: 32px;
		width: 32px;
		left: 0;
		top: 0;
		margin-left: 0;
		margin-top: 0;
	}
	.ui-dialog-titlebar-close {
		background-color: transparent;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 32px;
		margin: 0;
		opacity: .5;
		padding: 0;
		right: 0;
		top: 0;
		width: 32px;
		&:hover {
			opacity: 1;
		}
		span {
			margin: 0;
			padding: 0;
		}
	}
	h1,
	h2 {
		font-size: 1.8em;
		font-style: italic;
		font-weight: 400;
		margin: 5px 158px 5px 0;
	}
}

.no-close .ui-dialog-titlebar-close {
	display: none;
}

.ui-accordion .ui-accordion-content {
	padding: 0;
}

.ui-tabs {
	.ui-tabs-nav {
		height: 2.45em;
		padding: 0;
		li {
			border-color: $white;
			border-style: solid;
			border-width: 0 0 3px 0;
			&.ui-state-active {
				border-bottom-color: $citrus;
				a {
					color: $black;
					font-weight: 700;
				}
			}
			a {
				color: $nobel;
			}
		}
	}
	.ui-tabs-panel {
		background-color: $white;
		border: 1px solid $white;
		line-height: 1.5em;
		margin: 0;
		padding: 20px 10px;
		h3 {
			@media screen and (min-width: 768px) {
			display: none;
			}
		}
	}
}

#vertical-carousel {
	height: 19rem;
	position: relative;
	overflow: hidden;
	ul {
		height: 10000em;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		li {
			float: left;
			height: 19rem;
			width: 100%;
			.capture-product-id {
				display: none;
			}
		}
	}
	.jcarousel-nav {
		color: $nobel;
		cursor: pointer;
		font-size: 0.9em;
		font-weight: 700;
		position: absolute;
		right: 0;
		text-transform: uppercase;
		&:hover {
			color: $citrus;
		}
		&.inactive {
			display: none;
		}
	}
	.jcarousel-prev {
		bottom: 30px;
	}
	.jcarousel-next {
		bottom: 0;
	}
}

.ui-datepicker {
    background: white;
    border: 1px solid $darker-grey;
    border-radius: 3px;
    box-shadow: 10px 10px 42px -19px rgba(0,0,0,0.75);
    margin-top: 4px;
    width: 74%;

    @media screen and (min-width: 640px) {
        width: 330px;
    }

    td {
        span, a {
            text-align: center;
        }
    }

}

.ui-datepicker-current-day {
    background: $darker-grey;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    top: 6px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1.0;
    }
}

.ui-datepicker-prev:after {
    content: '<';
    margin-left: 13px;
}
.ui-datepicker-next:after {
    content: '>';
}

.ui-datepicker .ui-datepicker-prev-hover {
    left: 2px;
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 2px;
}
