footer {
	background-color: $charlotte;
	clear: both;
	font-size: 14px;
	letter-spacing: 1px;
	margin-top: 100px;
}
.footer-container {
    margin: 0 auto;
    max-width: 960px;
	padding: 50px 20px;

	@media screen and (min-width: 1024px) {
		padding: 50px 0;
	}

	.footer-item {
		box-sizing: border-box;
		display: block;
		width:100%;
		@media screen and (min-width: 768px) {
			float: left;
		}

		&.footer-address {
			@media screen and (min-width: 768px) {
				width: 55%;
			}
		}
	}
	.menu-links {

		margin-top: 2.6em;

		@media screen and (min-width: 768px) {
			width: 45%;
		}

		.content-asset {
			float: left;
			width: 100%;
		}

		ul {
			margin: 0 0 0 0;
			padding: 0;

			@media screen and (min-width: 768px) {
				float: left;
				width: 50%;
			}

			li {
				list-style: none;
				line-height: 2.2em;
			}
		}
	}
	h3 {
		color: $crusta;
		font-size: 1.6em;
		font-weight: 200;
		margin: 0 0 8px 0;
		text-transform: uppercase;
	}
	p {
		line-height: 2.2em;
		margin-top: 5px;
	}
	.menu-footer {
		margin: 0;
		padding: 0;
		
		li {
			float: none;
			list-style: none !important;
			line-height: 2.2em;
		}
	}
	a {
		color: $black-russian;
		&:hover {
			text-decoration: underline;
		}
	}
}

.social-links {
	padding-left: 0;
	li {
	display: inline;
	float: none;
	padding-right: 0.5em;
	}
}

#email-alert-signup {
	white-space: nowrap;
	input[type="text"] {
		border: none;
		margin-left: 0;
		width: 15em;
	}
	button {
		background-color: $dark-gray;
		border: none;
		color: $white;
		height: 31px;
		padding: 0;
		width: 31px;
	}
}

.footer-copy {
	clear: both;
	padding-top: 50px;
}

.postscript {
	background-color: $charlotte;
	color: $black-russian;
	font-style: italic;
	text-align: center;
}
