.search-suggestion-wrapper {
	@extend %clearfix;
	background-color: $white;
	border: 1px solid $nobel;
	box-shadow: 0 3px 3px 0 rgba(0,0,0,.25);
	position: absolute;
	width: 100%;
	z-index: 1;
	@media screen and (min-width: 768px) {
		&.full {
			margin-left: -75%;
			width: 175%;
			.phrase-suggestions {
				width: 42.8571429%;
				float: left;
			}
			.product-suggestions {
				width: 57.1428571%;
				float: right;
			}
		}
	}

	.hitgroup {
		color: $dim-gray;
		margin: .7rem 0 .7rem .7rem;
		.header {
			margin: 0 0 .5rem 0;
		}
		.hit {
			display: block;
			margin-left: 1rem;
			margin-bottom: .5rem;
			&:hover {
				text-decoration: underline;
			}
		}
		.parent-category {
			color: $nobel;
		}
	}
}

.phrase-suggestions {
	border-top: 1px solid $very-light-gray;
	@media screen and (min-width: 768px) {
		border-top: none;
	}
}

.product-suggestion {
	@extend %clearfix;
	border: 1px solid $white;
	border-top: 1px solid $very-light-gray;
	padding: .5em;
	&:hover,
	&:focus {
		background-color: $gainsboro;
		border: 1px solid $very-light-gray;
	}
	.product-image {
		float: left;
		text-align: center;
		width: 25%;
		img {
			max-width: 100%;
		}
	}
	.product-details {
		float: right;
		padding-top: .5em;
		width: 70%;
		.product-price {
			color: $black;
		}
	}
}

.search-phrase {
	color: $nobel;
	margin: 1em;
	.completed {
		font-style: italic;
		.original {
			text-decoration: underline;
			font-weight: bold;
		}
	}
	.corrected {
		font-weight: bold;
	}
}
