h1 {
	font-family: $sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	margin: .625rem 0;
	@media screen and (min-width: 768px) {
		font-size: 1.75em;
		margin: 0 0 14px;
	}
}

h2 {
	font-family: $sans-serif;
	font-size: 1.3em;
	text-transform: uppercase;
}

h3 {
	font-family: $sans-serif;
	font-size: 1.15em;
}

#secondary h1 {
	font-size: 1.75em;
	font-style: italic;
	font-weight: 400;
	margin: 0 0 14px;
}

.primary-content {
	dl {
		clear: both;
		overflow: hidden;
		dt {
			clear: both;
			float: left;
			margin: 2% 0 0;
			min-width: 15%;
		}
		dd {
			float: left;
			margin: 2% 0 0;
			padding: 0 0 0 5%;
		}
	}
}

.col-1 {
    box-sizing: border-box;
	float: left;
	margin: 4px 0;
	width: 48%;
}

.col-2 {
    box-sizing: border-box;
	float: right;
	margin: 4px 0;
	width: 48%;
}

.content-header {
	font-size: 1.75em;
	font-style: italic;
	font-weight: 400;
	margin: 0 0 14px !important;
	span {
		font-size: 0.75em;
		font-style: normal;
		font-weight: 400;
	}
}

.section-header {
	background-color: #EFEFEF;
	border-bottom: 1px solid $white;
	font-size: 1.1em !important;
	font-weight: 700;
	margin: 0;
	padding: 1em;
	position: relative;
	text-transform: uppercase;
}

.section-header-note {
	float: right;
	font-size: 0.9em;
	font-weight: 400;
	text-transform: none;

	@media screen and (min-width: 768px) and (max-width: 959px) {
		float: none;
	}
}

.back {
	display: block;
	margin: 0 0 10px;
}
