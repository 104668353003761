$ns: u-padding;

.#{$ns} {

    $padding-type: top right bottom left;

    $paddings: (
        'none':     0px,
        'xxs':      6px,
        'xs':       12px,
        'sm':       20px,
        'md':       33px,
        'ml':       40px,
        'lg':       60px,
        'xl':       72px
    );

    $breakpoints: (lg, mxl, ml, md, sm);
    $breakpoint-count: length($breakpoints) + 1;
    $loop-count: 1;


    @each $type in $padding-type {

        &-#{$type} {

            @each $padding-size, $padding in $paddings {

                &-#{$padding-size} {
                    padding-#{$type}: $padding !important;
                }
            }
        }
    }

    $breakpoint-count: $breakpoint-count - 1;
    $loop-count: $loop-count + 1;

    &-left {
        padding-left: 6px;
    }

    &-auto {
        padding-right: auto;
        padding-left: auto;
    }


    &-clear {

        &-top {
            padding-top: 0 !important;
        }

        &-bottom {
            padding-bottom: 0 !important;
        }

        &-vert {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        &-horz {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

    }

}
