#homepage-slider {
	margin-bottom: 25px;
	position: relative;
	overflow: hidden;
	width: 100%;
	.jcarousel-control {
		display: none;
		bottom: 0.5rem;
		right: 0.5rem;
		position: absolute;
		a {
			background: none repeat scroll 0 0 $gainsboro;
			border: 1px solid $very-light-gray;
			display: block;
			float: left;
			height: 24px;
			margin: 0 3px;
			opacity: .5;
			text-indent: -999em;
			width: 24px;
			&.active {
				background-color: $dim-gray;
			}
			&:hover {
				background-color: $dim-gray;
				opacity: .7;
			}
		}
	}
}

.home-bottom-slots {
	float: left;
	margin: auto auto 50px auto;
	max-width: 960px;
	text-align: center;
	width: 100%;

	h2 {
		font-size: 1.8em;
		font-weight: 200;

		@media screen and (min-width: 590px) {
			font-size: 2em;
		}

		a {
			color: $black-russian;
		}
	}
	.home-bottom-left,
	.home-bottom-center,
	.home-bottom-right {
		clear: none;
		
		@media screen and (min-width: 560px) {
			float: left;
			width: 47%;
			
		}
	}
}

.home-bottom-left {

	@media screen and (min-width: 560px) {
		margin-right: 6%;
	}
}

.home-bottom-feature {
	clear: both;
	padding: 0 7%;
	text-align: center;

	h2 {
		font-size: 1.5em;
		font-weight: 200;
	}

	p {
		font-size: 19px;
		line-height: 1.7em;
	}
}
